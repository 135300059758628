<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-card>
        <v-card-title>Company Selection Round</v-card-title>
      <v-card-text>
      </v-card-text>
    </v-card>
    
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    snackbar_msg: "",
    snackbar: false,
    color: "",
    org: null,
    //

  }),
  watch: {
    
  },
  methods: {
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  mounted() {
  axios
      .post("")
      .then(res => {
        if (res.data.msg == "200") {
          //4CAF50
        }
      })
      .catch(error => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
      })
      .finally(()=>{ 
      });       
  }
  
};

</script>

